/* =================================== */
/*  Blog Styles 
/* =================================== */
.blog-section {
  .blog-wrap {
    border-top: 1px solid #eee;
  }

  .overlay-bg {
    background: rgba($black, .2);
    z-index: 1;
  }

  .single-blog {
    overflow: hidden;
    border-radius: 5px;
    position: relative;
    @include transition();

    &:hover {
      -webkit-transform: translateY(-10px);
      -moz-transform: translateY(-10px);
      -ms-transform: translateY(-10px);
      -o-transform: translateY(-10px);
      transform: translateY(-10px);
      -webkit-box-shadow: 0 10px 50px -12px rgba(171, 171, 171, 0.9);
      -moz-box-shadow: 0 10px 50px -12px rgba(171, 171, 171, 0.9);
      box-shadow: 0 10px 50px -12px rgba(171, 171, 171, 0.9);
    }

    img {
      position: relative;
      width: 100%;
    }

    .blog-post-details {
      position: absolute;
      width: 100%;
      bottom: 0px;
      z-index: 1;
      padding: 20px;

      h4 {
        font-weight: 400;
      }

      ul {
        display: block;
        padding-bottom: 15px;

        li {
          display: inline-block;
          margin-right: 3px;
        }
      }

      a {
        font-weight: 400;
      }
    }

    h4,
    a {
      color: $white;
    }
  }

  .primary-btn {
    margin-top: 40px;
  }
}

.blog-lists-section {
  background: #eee;

  .single-blog-post {
    background: #fff;
    border-radius: 5px;
    box-shadow: 0px 30px 90px rgba(0, 0, 0, 0.14) !important;
    overflow: hidden;
    margin-bottom: 30px;

    &:hover {
      .post-thumb {
        img {
          transform: scale(1.1);
        }
      }
    }

    .post-thumb {
      overflow: hidden;

      .overlay-bg {
        background: rgba(#000000, .3);
        @include transition();
      }

      img {
        width: 100%;
        @include transition();
      }
    }

    .post-details {
      padding: 30px;
    }

    .tags {
      li {
        display: inline-block;
        margin-right: 10px;

        a {
          color: $primary-color;
          font-weight: 600;
          font-size: 14px;
        }
      }
    }

    h1 {
      margin: 10px 0px;
    }

    .user-details {
      img {
        width: 45px;
        border-radius: 50px;
      }

      p {
        margin-bottom: 0px;
      }

      .details {
        margin-left: 8px;
      }
    }
  }

  .pagination {
    .page-item {
      margin: 5px;
    }

    .page-link,
    i {
      color: $black;
      font-weight: 500;
    }

    .page-link {
      width: 35px;
      text-align: center;
      border-radius: 3px;

      &:hover {
        background: $primary-color;
        color: $white;

        i {
          color: $white;
        }
      }
    }

    .active .page-link {
      background: $primary-color;
      color: $white;
      border-color: transparent;
    }
  }

  .single-widget {
    background: $white;
    border-radius: 5px;
    padding: 30px;
    box-shadow: 0px 30px 90px rgba(0, 0, 0, 0.14) !important;
    overflow: hidden;
    margin-bottom: 30px;

    .widget-title {
      margin-bottom: 20px;
    }
  }

  .search-widget {
    .sidebar-form form {
      input {
        background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
        border: 1px solid #eee;
        padding: 0 10px;
        text-indent: 10px;
        transition: all 0.2s ease 0s;
        width: 100%;
        height: 50px;

        &:focus {
          outline: none;
        }
      }

      button {
        background: #222 none repeat scroll 0 0;
        border: 0 none;
        color: #fff;
        font-size: 20px;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        transition: all 0.3s ease 0s;
        width: 50px;

        &:hover {
          cursor: pointer;
        }

        &:focus {
          outline: none;
        }
      }
    }
  }

  .recent-post-widget {
    ul {
      li {
        margin-bottom: 20px;

        .thumbs {
          width: 40%;
        }

        .details {
          margin-left: 10px;

          p,
          h5 {
            font-size: 12px;
          }

          p {
            margin-bottom: 0px;
            font-weight: 400;
          }
        }
      }
    }
  }

  .social-widget {
    li {
      display: inline-block;
      margin-right: 10px;

      a {
        text-align: center;
        color: $white;
        background: $black;
        padding: 10px;
        width: 42px;
        display: block;
        @include transition();

        &:hover {
          background: $primary-color;
        }
      }
    }
  }

  .archive-widget {
    ul {
      li {
        margin-bottom: 10px;
        border-bottom: 1px solid #eee;
        padding-bottom: 10px;

        &:last-child {
          border-bottom: none;
        }

        a {
          font-weight: 500;
          color: $black;
          @include transition();

          &:hover {
            color: $primary-color;
          }
        }
      }
    }
  }

  .banner-widget {
    padding: 0px;
  }

  .tags-widget {
    ul {
      li {
        display: inline-block;

        a {
          font-size: 10px;
          margin-bottom: 5px;
          font-weight: 500;
          background: #000000;
          color: $white;
          display: inline-block;
          padding: 8px 18px;
          @include transition();

          &:hover {
            background: $primary-color;
          }
        }
      }
    }
  }
}

.blog-details {
  background: #fff;
  border-radius: 5px;
  box-shadow: 0px 30px 90px rgba(0, 0, 0, 0.14) !important;
  overflow: hidden;
  margin-bottom: 30px;

  &:hover {
    .post-thumb {
      img {
        transform: scale(1.1);
      }
    }
  }

  h1 {
    margin: 10px 0px;
  }

  .post-thumb {
    overflow: hidden;

    .overlay-bg {
      background: rgba(#000000, .3);
      @include transition();
    }

    img {
      width: 100%;
      @include transition();
    }
  }

  .tags {
    li {
      display: inline-block;
      margin-right: 10px;

      a {
        color: $primary-color;
        font-weight: 600;
        font-size: 14px;
      }
    }
  }

  .post-details {
    padding: 30px;
  }

  .user-details {
    img {
      width: 45px;
      border-radius: 50px;
    }

    p {
      margin-bottom: 0px;
    }

    .details {
      margin-left: 8px;
    }
  }

  .blockquote {
    border-left: 5px solid $primary-color;
    font-size: 14px;
    margin-bottom: 35px;
    margin-left: 20px;
    margin-top: 35px;
    padding-left: 15px;
  }
}

.comment-wrap {
  background: $white;
  border-radius: 5px;
  box-shadow: 0px 30px 90px rgba(0, 0, 0, 0.14) !important;
  overflow: hidden;
  padding: 30px;

  h3 {
    margin-bottom: 30px;
  }

  .media {
    border-bottom: 1px solid #eee;
    padding-bottom: 20px;

    &:last-child {
      border-bottom: none;
      padding-bottom: 0px;
    }
  }

  .media img {
    width: 90px;
    border-radius: 50px;
  }

  .replay-comment {
    border-bottom: none;
    border-top: 1px solid #eee;
    padding: 20px;
    margin-bottom: 0px !important;
  }

  .comments {
    margin-bottom: 30px;

    &:last-child {
      margin-bottom: 0px;
    }
  }

  .black-btn {
    min-width: 80px !important;
  }

  .media-body h5 {
    margin-bottom: 10px;
  }

  .comment-form h3 {
    margin-bottom: 30px;
  }
}

.comment-form {
  margin-top: 30px;
  background: $white;
  border-radius: 5px;
  box-shadow: 0px 30px 90px rgba(0, 0, 0, 0.14) !important;
  overflow: hidden;
  padding: 30px;

  .form-control {
    &:focus {
      box-shadow: none;
      border-color: #ced4da;
    }
  }

  .primary-btn {
    margin-top: 30px;
  }

  h3 {
    margin-bottom: 30px;
  }

  .form-col-wrap {
    margin-bottom: 30px;
  }
}