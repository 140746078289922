
section {
  min-height: max(640px, 100svh);
  position: relative;
  margin: 0 16px;

  .container {
    padding: 100px 0;
  }

  h2 {
    font-size: 72px;
    font-weight: 900;
    margin-bottom: 16px;
  }

  p {
    font-size: 30px;
    font-weight: 500;
  }

  .secondary-text {
    font-size: 20px;
    font-weight: 600;
  }

  .underline-primary {
    background: linear-gradient($primary-color, $primary-color) left bottom / 0 0.2rem no-repeat;
    transition: background-size 0.6s ease-in-out;
  }
}

.section-dark {
  h2 {
    color: #ffffff;
  }

  p {
    color: #dcdcdc;
  }
}

.background-image-size {
  position: absolute;
  height: 100%;
  width: 100%;
  padding: 32px 0;
  overflow: hidden;
  z-index: -1;
}

.background-image-crop {
  left: 16px;
  top: 16px;
  height: 100%;
  width: 100%;
  border-radius: 64px;
  overflow: hidden;
}

.background-image {
  filter: blur(10px) brightness(80%);
  -webkit-filter: blur(10px) brightness(80%);
  height: calc(100% + 32px);
  width: calc(100% + 32px);
  overflow: hidden;
  margin: -16px;
}

@media (max-width: 1024px) {
  section {
    h2 {
      font-size: 64px;
    }

    p {
      font-size: 28px;
    }
  }
}

@media (max-width: 768px) {
  section {
    h2 {
      text-align: center;
      font-size: 54px;
    }

    p {
      font-size: 24px;
      text-align: center;
    }
  }

  .background-image-size {
    padding: 16px 0;
  }
  .background-image-crop {
    left: 8px;
    right: 8px;
    border-radius: 32px;
  }
}

@media (max-width: 576px) {
  section {
    h2 {
      font-size: 48px;
    }

    p {
      font-size: 24px;
    }
  }

  .section-dark {
    margin: 0;
  }

  .background-image-size {
    padding: 0;
  }
  .background-image-crop {
    left: 0;
    right: 0;
    border-radius: 0;
  }
}

@media (max-width: 480px) {
  section {
    margin: 0 8px;

    h2 {
      font-size: 42px;
    }

    p {
      font-size: 20px;
    }
  }
}