.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.clickable-card {
  padding: 12px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 24px;
  background: #f8f8f8;
  box-shadow: 20px 20px 60px #d3d3d3,
  -20px -20px 60px #fbfbfb;
  transition: background 0.3s, box-shadow 0.3s;
  cursor: pointer;
}

.clickable-card:hover {
  background: #ededed;
  box-shadow: 20px 20px 80px #d1d1d1,
  -20px -20px 80px #fafafa;
}