/* =================================== */
/*  Download Styles 
/* =================================== */

.get-started-section {
  min-height: unset;
  margin: 0;

  background: url(../assets/download-bg.png);

  h4 {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .get-started-wrap {
    padding-top: 80px;
  }

  .primary-btn {
    margin-left: 80px;
  }

  .titles {
    padding-left: 80px;
  }
}