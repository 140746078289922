@font-face {
    font-family: Satoshi;
    src: url("fonts/Satoshi-Variable.ttf") format("truetype-variations");
    font-weight: 1 999;
}

.main {
    padding-top: 64px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    display: inline-flex;
    height: 100vh;
}

.adaptive {
    flex-direction: row;
    gap: 5%;
}

.slogan {
    color: #1B1B1F;
    font-size: 80px;
    font-family: Satoshi,sans-serif;
    font-weight: 900;
    word-wrap: break-word;
    width: 50vw;
}

.country-note {
    font-size: 12px;
    font-family: Satoshi,sans-serif;
    font-weight: 500;
}

select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';
    border: none;
    padding: 0;
    background: transparent;
    width: auto;
}

.loading {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
    margin: 40px 0;
}

.loading div {
    display: inline-block;
    position: absolute;
    left: 8px;
    width: 16px;
    background: #0258C9;
    animation: loading 1.2s cubic-bezier(0, 0.5, 0.75, 1) alternate infinite;
    border-radius: 8px;
}

.loading div:nth-child(1) {
    left: 8px;
    animation-delay: -0.24s;
}

.loading div:nth-child(2) {
    left: 32px;
    animation-delay: -0.12s;
}

.loading div:nth-child(3) {
    left: 56px;
    animation-delay: 0;
}

@keyframes loading {
    0% {
        top: 8px;
        height: 64px;
    }

    50%,
    100% {
        top: 24px;
        height: 32px;
    }
}


@media (max-width: 1600px) {
    .slogan {
        font-size: 70px;
    }
}

@media (max-width: 1350px) {
    .slogan {
        font-size: 70px;
    }
}

@media (max-width: 1150px) {
    .slogan {
        font-size: 60px;
    }
}

@media (max-width: 1000px) {
    .main {
        justify-content: start;
        height: auto;
    }

    .adaptive {
        flex-direction: column;
        padding: 160px 8px 64px 8px;
        gap: 64px;
    }

    .slogan {
        text-align: center;
        width: auto;
    }
}

@media (max-width: 550px) {
    .slogan {
        font-size: 50px;
    }
}

@media (max-width: 450px) {
    .slogan {
        font-size: 40px;
    }
}

@media (max-width: 350px) {
    .slogan {
        font-size: 30px;
    }
}