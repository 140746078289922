@import "variables";

.phone-frame {
  // Border width and radius are set by Phone component
  border-style: solid;
  border-color: $phone-frame-color;
  width: 100%;
  height: 100%;
}

.phone-size {
  min-height: 500px;
  height: 80vh;
  aspect-ratio: 9/19;
}

