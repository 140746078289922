/* =================================== */
/*  Font Family and Colors Styles
/* =================================== */

$primary-font: Satoshi;


$primary-color: #0058C9;
$secondary-color: #315ca8;

$title-color: #2e2e30;
$subtitle-color: #424242;
$text-color: #8c8d9e;

$white: #fff;
$offwhite: #fafcff;
$black: #222;
$phone-frame-color: #555;
