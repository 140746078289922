/* =================================== */
/*  Stat Styles 
/* =================================== */

.stat-section {
  min-height: unset;

  .container {
    padding: 32px;
  }
  .stat-card {
    background: $primary-color;
    border-radius: 64px;
    padding: 0 32px;
  }

  h1, h4 {
    color: $white;
  }

  .single-stat {
    color: $white;

    span {
      font-size: 35px;
    }

    h1 {
      font-weight: 800;
      margin: 20px 0px;
    }
  }
}