/* =================================== */

/*  Clients Styles 
/* =================================== */

.clients-wrap {
  padding: 30px 0px;
  border-bottom: 1px solid #eee;
  border-top: 1px solid #eee;
}

.single-client {
  text-align: center;
}

.contact-wrap {
  padding: 60px 0px;

  h3 {
    font-weight: 400;

    i {
      margin-right: 10px;
    }
  }
}