/* =================================== */
/*  Banner Styles 
/* =================================== */
.banner-section {
  min-height: 950px;

  .overlay-bg {
    @extend .gradient-bg;
    opacity: .6;
  }

  .banner-left {
    h1 {
      font-size: 80px;
      font-weight: 900;
      padding: 32px 0;
    }

    h2 {
      color: $subtitle-color;
      font-size: 35px;
      font-weight: 900;
    }

    h4 {
      font-weight: 300;
      margin: 20px 0px;
    }
  }

  .banner-right {
    background: url(../assets/screen-bg.svg) center no-repeat;
    background-size: contain;
    max-height: 80vh;
  }

  .wave {
    svg {
      position: absolute;
      width: 100%;
      bottom: -1px;
      fill: #fff;
    }

    svg path:nth-child(1) {
      opacity: 0.15;
    }

    svg path:nth-child(2) {
      opacity: 0.3;
    }
  }
}
