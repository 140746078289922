
.jm-background {
  top: 0;
  bottom: 0;
  position: fixed;
  z-index: -100;
  width: 100vw;

  .jm-background-upper {
    left: 0; top: 0; position: absolute; width: max(30vw, 250px);
  }

  .jm-background-lower {
    right: 0; bottom: 0; position: absolute; width: max(30vw, 250px);
  }
}