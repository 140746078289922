@import "variables";

//.phone-frame {
//  --phone-scale: 1.0;
//  border: max(1.8vh, 11px) solid $phone-frame-color;
//  border-radius: max(8vh, 54px);
//  width: calc(100% / var(--phone-scale));
//  height: calc(100% / var(--phone-scale));
//  transform: scale(var(--phone-scale));
//  position: absolute;
//  top: calc(-50% *  (1 / (var(--phone-scale)) - 1));
//  left: calc(-50% *  (1 / (var(--phone-scale)) - 1));
//}
//
//.phone-size {
//  object-fit: cover;
//  object-position: 0 0;
//  min-height: 500px;
//  height: 80vh;
//  aspect-ratio: 9/18.5;
//  position: relative;
//}

.phone-anim {
  display: flex;
  justify-content: center;
  -webkit-animation: jump 5s linear 0s infinite normal forwards;
  animation: jump 5s linear 0s infinite normal forwards;
}

@keyframes jump {
  0% {
    transform: translateY(0);
  }

  20% {
    transform: translateY(0);
  }

  41% {
    transform: translateY(-10px);
  }

  50% {
    transform: translateY(-5px);
  }

  50% {
    transform: translateY(-5px);
  }

  80% {
    transform: translateY(0px);
  }

  100% {
    transform: translateY(0);
  }
}

@-webkit-keyframes jump {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  20% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  41% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }

  50% {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
  }

  50% {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
  }

  80% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
