/* =================================== */
/*  Common Styles 
/* =================================== */
.scroll-top {
  width: 40px;
  height: 40px;
  line-height: 44px;
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: 999;
  font-size: 22px;
  color: #fff;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  background: $primary-color;
  border-radius: 12px;
  box-shadow: 0 2px 15px rgba(0, 0, 0, .25);
  -webkit-transition: .2s ease-in-out;
  transition: .2s ease-in-out;
}

.section-title {
  margin-bottom: 75px;
}

.gradient-bg {
  background: -moz-linear-gradient(0deg, #0058c9 0%, #691cff 100%);
  background: -webkit-linear-gradient(0deg, #0058c9 0%, #691cff 100%);
  background: -ms-linear-gradient(0deg, #0058c9 0%, #691cff 100%);
}

.offwhite-bg {
  background-color: #eee;
}

.primary-btn {
  text-align: center;
  border-radius: 16px;
  background: $primary-color;
  padding: 15px 40px;
  font-size: 16px;
  font-weight: 700;
  min-width: 160px;
  display: inline-block;
  color: $white;
  @include transition();

  &:hover {
    color: $white;
    box-shadow: 0px 30px 90px rgba($primary-color, 0.2) !important;
    -ms-transform: translateY(-2px);
    transform: translateY(-2px);
    -webkit-transform: translateY(-2px);
  }
}

