/* =================================== */
/*  Price Styles 
/* =================================== */

.price-section {
  .price-left {
    h4 {
      font-size: 35px;
    }

    p {
      color: $black;
      padding: 10px 0px;
      max-width: 320px;
    }
  }

  .price-right {
    .main {
      margin: -30px 0;
      background: $primary-color;
      color: $white;

      i, h4, h2 {
        color: $white;
      }

      .bottom-wrap {
        border-color: $white;
      }
    }
  }

  .single-price {
    width: 100%;
    border-radius: 5px;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    margin: 0 auto;
    -webkit-box-shadow: 5px 5px 30px rgba(0, 0, 25, .05);
    box-shadow: 5px 5px 30px rgba(0, 0, 25, .05);
    padding: 40px 30px;
    z-index: 1;
    @include transition();

    i {
      position: relative;
      font-size: 40px;
      color: $primary-color;
    }

    h4 {
      position: relative;
      margin: 10px 0;
    }

    .bottom-wrap {
      border-top: 1px solid rgb(140, 141, 158);
      margin-top: 20px;
      padding-top: 20px;

      i {
        font-size: 10px;
      }

      ul li {
        margin-bottom: 10px;

        &:last-child {
          margin-bottom: 0px;
        }
      }

      .primary-btn-w {
        background: $white;
        color: $black;
        margin-top: 30px;
      }

      .primary-btn {
        margin-top: 30px;
      }
    }
  }
}